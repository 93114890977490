.login_item {
  display: block;
  margin-bottom: 30px;
  max-width: 400px;
}

.login_item-title {
  margin-bottom: 15px;
}

.login_item-content {
  margin-bottom: 30px;
}

.login_item-input {
  margin-bottom: 20px;
}

.login_item-input label {
  display: block;
  margin-bottom: 15px;
}

.login_item-input input {
  display: block;
  min-width: 300px;
}
