.home {
  display: inline-block;
  margin-bottom: 40px;
}

.home a {
  display: block;
}

.action {
  max-width: 400px;
}

.action_code {
  margin-bottom: 20px;
}

.action_code p {
  margin: 0px 0px 10px 0px;
}

.action_code input {
  display: inline-block;
  min-width: 300px;
}

.action_token-display {
  max-width: 400px;
}

textarea {
  margin-bottom: 40px;
}
